<template>
  <v-container>
    <h1>Política de Privacidade</h1>
  </v-container>
</template>

<script>
export default {}
</script>

<style></style>
